<script>
    import Multiselect from "vue-multiselect";
    import VueToastr from "vue-toastr";
    
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
import { BASE_URL } from '../../../baseconstant';
    
    /**
     * Orders component
     */
    export default {
      components: { Layout, Multiselect, PageHeader, VueToastr },
      page: {
        title: "App Version",
        meta: [
          {
            name: "description",
            content: appConfig.description,
          },
        ],
      },
      data() {
        return {
          title: "App Update",
          isBusy: false,
          items: [
            {
              text: "App",
            },
            {
              text: "Versions",
              active: true,
            },
          ],
          roleData: [],
          totalRows: 1,
          currentPage: 1,
          perPage: 10,
          pageOptions: [10, 25, 50, 100],
          filter: null,
          filterOn: [],
          sortBy: "age",
          sortDesc: false,
          fields: [
            {
              key: "index",
              label: "S/N",
            },
            {
              key: "platform",
              label: "Name",
            },
            {
              key: "version",
              label: "Version",
            },
            {
              key: "build_number",
              label: "Build Number",
            },
            {
              key: "store_link",
              label: "Link",
            },
            {
              key: "is_required",
              label: "Status",
            },
            {
              key: "created_at",
              label: "Date",
            },
            "action",
          ],
          role: {
            // id: this.id,
            name: this.name,
            permissions: this.permissions,
          },
          app: {
            id: this.id,
            version: this.version,
            platform: this.platform,
            store_link: this.store_link,
            build_number: this.build_number,
            is_required: this.is_required
          },
          options: [],
          option: ["ios", "android"],
          permissions: [],
          allSelected: false,
          indeterminate: false,
          required: [true, false]
        };
      },
      middleware: "authentication",
      computed: {
        /**
         * Total no. of records
         */
        rows() {
          return this.roleData.length;
        },
      },
      mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
        this.fetchData();
        this.fetchPermission();
      },
      methods: {
        toggleAll(checked) {
          this.permissions = checked ? this.options.slice() : []
        },
        fetchData() {
            this.isBusy =  true
            this.axios.get(BASE_URL+'/admin/updates/all')
            .then((res) => {
                // console.log(res.data.data);
                this.roleData = res.data.data
            })
            .catch((err) => {
                // console.log(err.response?.data?.error);
                this.$refs.mytoast.Add({
                msg: err.response?.data?.error,
                clickClose: false,
                timeout: 5000,
                position: "toast-top-right",
                type: "error",
              });
            })
            .finally(() => {
                this.isBusy =  false
            });
        },
        addRole() {
            this.isBusy =  true
            console.log(this.role)
            this.axios.post(BASE_URL+'/admin/updates/create', this.app)
            .then(() => {
                // console.log(res.data.data);
                this.fetchData();

                this.$refs.mytoast.Add({
                msg: "App Created Successfully",
                clickClose: false,
                timeout: 5000,
                position: "toast-top-right",
                type: "success",
              });
            })
            .catch((err) => {
                // console.log(err.response);
                this.$refs.mytoast.Add({
                msg: err.response?.data?.error,
                clickClose: false,
                timeout: 5000,
                position: "toast-top-right",
                type: "error",
              });
            })
            .finally(() => {
                this.isBusy =  false
            });
        },
        editRole() {

            this.axios.put(BASE_URL+'/admin/updates/update', this.app)
            .then((res) => {
                console.log(res.data.data);
                this.fetchData();

                this.$refs.mytoast.Add({
                msg: "App Edited Successfully",
                clickClose: false,
                timeout: 5000,
                position: "toast-top-right",
                type: "success",
              })
            })
            .catch((err) => {
                console.log(err);
                console.log(this.role);

                this.$refs.mytoast.Add({
                msg: err.response.data.details,
                clickClose: false,
                timeout: 5000,
                position: "toast-top-right",
                type: "error",
              });
            })
            .finally(() => {
                this.isBusy =  false
            });
        },
        deleteRole(id) {
            this.axios.delete(BASE_URL+'/admin/updates/delete/' + id)
            .then((res) => {
                console.log(res.data.data);
                this.fetchData();

                this.$refs.mytoast.Add({
                msg: "Role Deleted Successfully",
                clickClose: false,
                timeout: 5000,
                position: "toast-top-right",
                type: "success",
              })
            })
            .catch((err) => {
                console.log(err);
                this.$refs.mytoast.Add({
                msg: err.response.data.details,
                clickClose: false,
                timeout: 5000,
                position: "toast-top-right",
                type: "error",
              });
            })
            .finally(() => {
                this.isBusy =  false
            });
        },
        fetchPermission(){
          this.axios.get(BASE_URL+'/admin/permissions')
          .then((res) => {
                // console.log(res.data.data);
                this.options = res.data.data;
            })
            .catch((err) => {
                // this.error = true
                // console.log(err);
                this.$refs.mytoast.Add({
                msg: err.response?.data?.error,
                clickClose: false,
                timeout: 5000,
                position: "toast-top-right",
                type: "error",
              });
            })
            .finally(() => {
                this.isBusy =  false
            });
        },
        emptyVal() {
            this.app.id = null
            this.app.version = null
            this.app.platform = null
            this.app.store_link = null
            this.app.build_number = null
            this.app.is_required = false
        },
        getRoleInfo(item) {
            this.app.id = item.id
            this.app.version = item.version
            this.app.platform = item.platform
            this.app.store_link = item.store_link
            this.app.build_number = item.build_number
            this.app.is_required = item.is_required
        },
  
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length;
          this.currentPage = 1;
        },
      },
      watch: {
        permissions(newValue) {
        // Handle changes in individual flavour checkboxes
        if (newValue.length === 0) {
          this.indeterminate = false
          this.allSelected = false
        } else if (newValue.length === this.options.length) {
          this.indeterminate = false
          this.allSelected = true
        } else {
          this.indeterminate = true
          this.allSelected = false
        }
      }
    }
    };
    </script>
    
    <template>
      <Layout>
        <PageHeader :title="title" :items="items" />
        <vue-toastr ref="mytoast"></vue-toastr>
        <!-- ::START ADD Role Modal -->    
        <b-modal id="modal-add-role" title="Add Role" title-class="font-18" hide-footer>
              <label for="" class="m-2">Versions: </label>
              <input type="text" v-model="app.version" placeholder="Enter App Version..." class="m-2 form-control">
              <label class="m-2">Platform: </label>
              <multiselect
                class="m-2"
                v-model="app.platform"
                :options="option"
                :multiple="false"
              ></multiselect>
              <label for="" class="m-2">Build Number: </label>
              <input type="text" v-model="app.build_number" placeholder="Enter Build Number..." class="m-2 form-control">
              <label for="" class="m-2">Link: </label>
              <input type="text" v-model="app.store_link" placeholder="Enter Link..." class="m-2 form-control">
              <label class="m-2">Required: </label>
              <!-- <multiselect
                class="m-2"
                v-model="app.is_required"
                :options="required"
                :multiple="false"
              ></multiselect> -->
              <li class="list-inline-item">
                 <toggle-button @change="toggleServiceStatus(item)" v-model="app.is_required" :sync="true" :value="app.is_required==0?false:true" :labels="{checked: 'On', unchecked: 'Off'}"/>
                </li>

            <div class="modal-footer">
                  <button @click="addRole(), $bvModal.hide('modal-add-role')" type="button" class="btn btn-primary">
                      Save changes
                  </button>
                  <b-button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                      @click="$bvModal.hide('modal-add-role')"
                      >
                      Close
                  </b-button>
              </div>
        </b-modal>
        <!-- ::END ADD Role Modal -->

        <!-- ::START EDIT Role Modal -->    
        <b-modal id="modal-edit-role" title="Edit App" title-class="font-18" hide-footer>
          
            <label for="" class="m-2">Versions: </label>
              <input type="text" v-model="app.version" placeholder="Enter App Version..." class="m-2 form-control">
              <label class="m-2">Platform: </label>
              <multiselect
                class="m-2"
                v-model="app.platform"
                :options="option"
                :multiple="false"
              ></multiselect>
              <label for="" class="m-2">Build Number: </label>
              <input type="text" v-model="app.build_number" placeholder="Enter Build Number..." class="m-2 form-control">
              <label for="" class="m-2">Link: </label>
              <input type="text" v-model="app.store_link" placeholder="Enter Link..." class="m-2 form-control">
              <label class="m-2">Required: </label>
              <!-- <multiselect
                class="m-2"
                v-model="app.is_required"
                :options="required"
                :multiple="false"
              ></multiselect> -->
                <li class="list-inline-item">
                 <toggle-button v-model="app.is_required" :value="app.is_required === 1 ? true : false" :labels="{checked: 'On', unchecked: 'Off'}"/>
                </li>

              
            <div class="modal-footer">
              <button @click="editRole(), $bvModal.hide('modal-edit-role')" type="button" class="btn btn-primary">
                  Save changes
              </button>
              <b-button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  @click="$bvModal.hide('modal-edit-role')"
                  >
                  Close
              </b-button>
          </div>
        </b-modal>
        <!-- ::END EDIT Role Modal -->

        <!-- ::START GET Role Modal -->    
        <b-modal id="modal-get-role" title="Role Details" title-class="font-18" hide-footer>
            <div>
              
              <p>Name: <strong>{{role.name}}</strong></p> 
              <p>Permissions: </p>
              <ul v-for="permission in role.permissions" :key="permission.id">
                  <li>
                      <strong>{{permission.name}}</strong>
                  </li>
              </ul> 
            </div>
            <div class="modal-footer">
                <b-button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    @click="$bvModal.hide('modal-get-role')"
                    >
                    Close
                </b-button>
            </div>
        </b-modal>
        <!-- ::END GET Role Modal -->

        <!-- ::START DELETE Role Modal -->
        <b-modal id="modal-delete-role" title="Delete Role" title-class="font-18" hide-footer>
            <p>Are you sure you want to delete "{{app.platform}}" </p>
            
            <div class="modal-footer">
                <button @click="deleteRole(app.id), $bvModal.hide('modal-delete-role')" type="button" class="btn btn-primary">
                    Delete
                </button>
                <b-button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    @click="$bvModal.hide('modal-delete-role')"
                    >
                    Close
                </b-button>
            </div>
        </b-modal>
        <!-- ::END DELETE Role Modal -->

        <div class="row">
          <div class="col-12">
            <button
            type="button"
            class="btn btn-primary mb-3 brand-primary"
            v-b-modal.modal-add-role
            @click="emptyVal()"
          ><i class="mdi mdi-plus me-1"></i> Add Updates
          </button>
            <div class="table table-centered datatable dt-responsive nowrap table-card-list dataTable no-footer dtr-inline">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center fw-normal">
                      Show&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-end"
                  >
                    <label class="d-inline-flex align-items-center fw-normal">
                      Search:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ms-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
              
              <b-table
                :busy="isBusy"
                table-class="table table-centered datatable table-card-list"
                thead-tr-class="bg-transparent"
                :items="roleData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
              >
                <template #table-busy>
                    <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                    </div>
                </template>
                <template #empty="scope">
                    <p class="text-center p-3">{{ scope.emptyText }}</p>
                </template>
                <template v-slot:cell(index)="data">
                  {{ data.index + 1 }}
                </template>
    
                <template v-slot:cell(platform)="data">
                  <p class="font-bold text-capitalize">{{ data.item.platform }}</p>
                </template>
                <template v-slot:cell(store_link)="data">
                  <a :href="data.item.store_link" target="_blank">{{ data.item.store_link }}</a>
                </template>
                <template v-slot:cell(is_required)="data">
                  <div
                    class="badge bg-pill bg-soft-info font-size-12"
                    :class="{
                      'bg-soft-success': data.item.is_required === 1,
                      'bg-soft-danger': data.item.is_required === 0,
                    }"
                  >
                  <span v-if="data.item.is_required == 1">Required</span>
                  <span v-if="data.item.is_required == 0">Not Required</span>
                  </div>
                </template>
                <template v-slot:cell(created_at)="data">
                  <p>{{data.item.created_at | formatDate}}</p>
                </template>
                <template v-slot:cell(action)="{ item }">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <a
                        href="javascript:void(0);"
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                        @click="getRoleInfo(item)"
                        v-b-modal.modal-edit-role
                      >
                        <i class="uil uil-pen font-size-18"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        href="javascript:void(0);"
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="getRoleInfo(item)"
                        v-b-modal.modal-delete-role
                      >
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>
                  </ul>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </template>
    